import React, { useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Chart from 'react-apexcharts';
import moment from 'moment';

const earliestRecord = moment('2021-11-14T16:42:25.085Z');
const apiUrl = 'https://rddbc4kb5i.execute-api.us-east-2.amazonaws.com/prod';

function App() {
  const { start, end } = useParams();
  const period = {
    start: moment(start),
    end: moment(end),
    previous: {
      start: moment(start).add(-24, 'hour'),
      end: moment(end).add(-24, 'hour'),
    },
    next: {
      start: moment(start).add(24, 'hour'),
      end: moment(end).add(24, 'hour'),
    }
  };
  const [data, setData] = useState(false);
  useEffect(() => {
    fetch(`${apiUrl}/${period.start.format()}/${period.end.format()}`)
      .then((r) => r.json())
      .then(setData)
      .catch(console.error);
  }, [period.start, period.end]);
  return (period.start < earliestRecord)
    ? (
        <Navigate to={`/${earliestRecord.format()}/${earliestRecord.add(1, 'day').format()}`} />
      )
    : (
        <Container fluid>
          <Row style={{padding: '20px'}}>
            <h1>
              calamari (on kusama) block skip statistics
            </h1>
          </Row>
          <Row style={{padding: '20px'}}>
            <Col>
              {
                (period.previous.end > earliestRecord)
                  ? (
                      <a className="btn btn-outline-info" href={`/${period.previous.start.format()}/${period.previous.end.format()}`}>
                        <i className="bi bi-arrow-bar-left"></i> - 24 hours
                      </a>
                    )
                  : null
              }
            </Col>
            <Col className="text-center">
              <dl>
                <dt>
                  start
                </dt>
                <dd>
                  {period.start.format()}
                </dd>
                <dt>
                  end
                </dt>
                <dd>
                  {period.end.format()}
                </dd>
              </dl>
            </Col>
            <Col className="text-end">
              {
                (period.next.start < moment())
                  ? (
                      <a className="btn btn-outline-info" href={`/${period.next.start.format()}/${period.next.end.format()}`}>
                        + 24 hours <i className="bi bi-arrow-bar-right"></i>
                      </a>
                    )
                  : null
              }
            </Col>
          </Row>
          <Row>
            {
              (!!data)
                ? (
                    <Chart
                      options={{
                        colors : ['#770055', '#b2d66b', '#8c8cb7'],
                        chart: {
                          type: 'bar',
                          stacked: true,
                        },
                        plotOptions: {
                          bar: {
                            borderRadius: 4,
                            horizontal: true
                          }
                        },
                        xaxis: {
                          categories: data.stats.map(x => x.name)
                        }
                      }}
                      series={ ['skipped', 'processed', 'total'].map(c => ({
                        name: c,
                        data: data.stats.map(x => x[c])
                      })) }
                      type="bar"
                      height="500"
                    />
                  )
                : null
            }
          </Row>
        </Container>
      );
}

export default App;
